import React, { useRef } from "react";
import { graphql } from "gatsby";
import Page from "../components/Page";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { GatsbyImage } from "gatsby-plugin-image"
import BlogCard from "../components/Blog/LargeBlogCard";
import { Link } from "gatsby";

import { BiRightArrowAlt } from "react-icons/bi";

function Home({ data }) {
  const { posts } = data.blog;
  const { width, height } = useWindowDimensions();

  const allContent = useRef(null);
  const blogPosts = useRef(null);

  function getBlogElements() {
    if (width > 1600) return 4;
    else if (width > 1200) return 3;
    else if (width > 800) return 2;
    else return 3;
  }

  return (
    <>
      <Page transparentNavbar={true}>

        <div className="homepage">
          <div className="relative bg-gray-800">
            <div className="w-full  absolute z-50 top-16 lg sm:top-1/4 px-4 sm:px-8 lg:px-16 xl:px-20">
              <div className="max-w-screen-xl mx-auto px-4 sm:px-8 lg:px-16 xl:px-20 lg:mt-7">
                <h1 className="font-bold text-3xl max-w-xl md:text-5xl md:max-w-2xl text-white stroke-indigo-300 leading-tight mb-4">
                  Små förbättringar ger stora resultat i slutändan.
                </h1>
                <p className="text-white text-lg max-w-lg font-light mb-4">Vi hjälper dig förbättra ditt liv och ekonomi genom personlig utveckling och bättre ekonomiska val.</p>
                <Link to={"/blogg/"} className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:ring-indigo-300">
                  Läs mer
                  <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </Link>
                <Link to="/inkomster/" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900   rounded-lg bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:ring-gray-100">
                  Inkomster
                </Link>
              </div>

            </div>
            <GatsbyImage
              image={data.homeImage.childImageSharp.gatsbyImageData}
              className="h-96 min-h-full w-full sm:h-screen opacity-50"
            />
          </div>
          <div className="bg-gray-100">
            <div className="max-w-screen-xl mx-auto py-16 px-4 sm:px-8 lg:px-16 xl:px-20 ">
              <h2 className="font-bold text-3xl md:text-4xl max-w-xl text-gray-900 leading-tight mb-4">Vårt fokus</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 py-4">
                <div>
                  <h4 className="font-bold text-xl text-gray-900 leading-tight mb-2">1. Personlig utveckling</h4>
                  <p className="text-gray-600">
                    Att vi kommer ta oss väldigt långt om vi förbättras lite varje
                    dag är något jag tror väldigt starkt på, och detta gäller även
                    små förbättringar. Även många små förbättringar kan ge mycket
                    stora resultat tillsammans under en längre tidsperiod. Och
                    detta är framförallt målet med denna sidan. Att ge dig många
                    små tips som du kan applicera för att förbättra dig själv och
                    ditt egna liv.
                  </p>
                </div>
                <div>
                  <h4 className="font-bold text-xl text-gray-900 leading-tight mb-2">2. Sparande</h4>
                  <p className="text-gray-600">
                    Albert Einstein skall ha sagt att ränta-på-ränta-effekten är
                    världens åttonde underverk. Kanske vet du ännu inte var
                    ränta-på-ränta är men det är en av många saker som du kommer
                    lära dig mer om i spar och ekonomi-avsittet. Detta avsnittet
                    kommer fokusera mycket på hur vi kan förbättra vår ekonomi för
                    att på sikt få en bättre vardag och levnadsstandard.
                  </p>
                </div>
                <div>
                  <h4 className="font-bold text-xl text-gray-900 leading-tight mb-2">3. Produktivitet</h4>
                  <p className="text-gray-600">
                    Med hjälp av grymma tips och nya verktyg i vår
                    produktivitetsguide kommer du lära dig hur du kan bli mer
                    effektiv och på så sätt få mer tid över till sånt som du
                    verkligen tycker om. Visst låter det bra? Metoderna och tipsen
                    som går att hitta här på sidan är mycket enkla att förstå och
                    väldigt praktiska. Jag hoppas och tror att de kommer komma
                    till stor användning för dig.
                  </p>
                </div>
                <div>
                  <h4 className="font-bold text-xl text-gray-900 leading-tight mb-2">4. Hälsa</h4>
                  <p className="text-gray-600">
                    Vad är nycklarna bakom en hälsosam livsstil? Enligt mig finns
                    det tre ben inom en hälsosam livsstil och dessa är kost,
                    rörelse och sömn. Jag kommer gräva djupare inom de här tre
                    områden och min förhoppning är att du efter läsningen kommer
                    ha en bättre förståelse om varför de är så viktiga. Du kommer
                    också få grymma boktips så du kan gräva dig vidare på egen
                    hand.
                  </p>
                </div>
              </div>
            </div>

          </div>

          <div className="max-w-screen-xl mx-auto py-16 px-4 sm:px-8 lg:px-16 xl:px-20 ">
            <h2 className="font-bold text-3xl md:text-4xl max-w-xl text-gray-900 leading-tight mb-4">Senaste från bloggen</h2>
            <div className="blog-grid-3" ref={blogPosts}>
              {posts.slice(0, getBlogElements()).map((post) => (
                <article key={post.id}>
                  <BlogCard
                    author={post.frontmatter.author}
                    title={post.frontmatter.title}
                    date={post.frontmatter.date}
                    text={post.excerpt}
                    image={post.frontmatter.featuredImage?.childImageSharp.gatsbyImageData}
                    link={"/blogg/" + post.frontmatter.pathname + "/"}
                  />
                </article>
              ))}
            </div>
            <Link to={"/blogg/"}>
              <button className="mt-12 text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-full text-md px-5 py-3 text-center inline-flex items-center">
                Visa alla <BiRightArrowAlt />
              </button>
            </Link>
          </div>


        </div>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query home {
    blog: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } },
      sort: { fields: [frontmatter___date], order: DESC },
      limit: 3,
    ) {
      posts: nodes {
        frontmatter {
          date
          title
          pathname
          author
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        excerpt
        id
      }
    }
    homeImage: file(relativePath: { eq: "homepage.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default Home;
